import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { InstantSearch, Configure } from "react-instantsearch-dom"

import Layout from "../../components/Layout"
import { Heading, Featured } from "../../components/utils"
import { searchClient } from "../../utils/algolia/searchClient"
import { CustomHits } from "../../components/search"

const Sold = ({ data }) => (
  <Layout transparentHeader>
    <HelmetDatoCms
      htmlAttributes={{
        lang: data.sold.locale,
      }}
      seo={data.sold.seoMetaTags}
    >
      {data.sold._allSlugLocales.map((version, index) => (
        <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${version.value}`} key={index} />
      ))}
    </HelmetDatoCms>
    <div className="page">
      {data.sold.featured &&
        <Featured
          data={data.sold.featured}
          title={data.sold.featured.title}
          subtitle={
            <>
              {data.sold.featured.propertyStatus.name}
              <span className="mx-2">·</span>
              {data.sold.featured.city.name}, {data.sold.featured.city.country.name}
            </>
          }
          image={data.sold.featured.gallery[0].fluid}
        />
      }
      <div className="container py-12 md:py-24">
      <Heading title={data.sold.heading} className="mb-12" />
        <InstantSearch
          searchClient={searchClient}
          indexName={`${process.env.GATSBY_ALGOLIA_INDEX_NAME}_${data.sold.locale}`}
          analytics={false}
        >
          <Configure
            filters={`(propertyStatus.originalId:34805497 OR propertyStatus.originalId:34805527) AND NOT reference:${data.sold.featured.reference}`}
            hitsPerPage={24}
          />
          <CustomHits global={global} />
        </InstantSearch>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query SoldQuery($locale: String!, $slug: String!) {
    sold: datoCmsSold(
      locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      ...Sold
    }
    search: datoCmsSearch(locale: { eq: $locale }) {
      ...Search
    }
  }
`

export default Sold
